import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['card'];

  onPostSuccess(event) {
    let [data] = event.detail;
    $(this.cardTarget).replaceWith(data.html);
    this.cardTarget.scrollIntoView({ behavior: 'smooth' });
    this.reinitSelect2();
  }

  onPostError(event) {
    let [data] = event.detail;
    $(this.cardTarget).replaceWith(data.html);
    this.cardTarget.scrollIntoView({ behavior: 'smooth' });
    this.reinitSelect2();
  }

  reinitSelect2() {
    $('select').each(function(i, el) {
      if (!$(this).data('select2-id') && !$(this).hasClass('flatpickr-monthDropdown-months')) {
        $(this).select2({
          theme: 'bootstrap4'
        });
      }
    })
  }
}