// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import AsyncFormController from './async_form_controller'
import NestedFormController from './nested_form_controller'
import Flatpickr from './flatpickr_controller';
import WithdrawalDaysToggleController from './withdrawal_days_toggle_controller';

const application = Application.start();
application.register('async-form-controller', AsyncFormController);
application.register('nested-form-controller', NestedFormController);
application.register('withdrawal-days-toggle', WithdrawalDaysToggleController);
application.register('flatpickr', Flatpickr);