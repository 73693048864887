/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import 'bootstrap';
import 'popper.js';
import 'select2';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import '../controllers'

$(document).on('change', 'input', function () {
  $('#export_pdf').attr('href', function() {
    let clean_uri = this.href.substring(0, this.href.indexOf("?"));
    if($('.nav-pills')) {
      return clean_uri + '?' + $.param($('div.active').find('form').serializeArray());
    } else {
      return clean_uri + '?' + $.param($('form').serializeArray());
    }
  });
});

$(document).on('turbolinks:load', function() {
  $('select').each(function(i, el) {
    if (!$(this).data('select2-id') && !$(this).hasClass('flatpickr-monthDropdown-months')) {
      let element = $(this);
      let tags = element.data('tags') || false;
      element.select2({
        theme: 'bootstrap4',
        tags: tags
      })
    }
  })
});

$(document).on('select2:select select2:unselect', (e) => {
  const delegate_event = new Event('change', { bubbles: true });
  e.target.dispatchEvent(delegate_event);
});
