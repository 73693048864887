import Flatpickr from 'stimulus-flatpickr'

import { Slovenian } from "flatpickr/dist/l10n/sl.js";
import { Italian } from "flatpickr/dist/l10n/it.js";

export default class extends Flatpickr {
  locales = {
    sl: Slovenian,
    it: Italian
  };

  initialize() {
    this.config = {
      locale: this.locale,
      enableTime: true,
      time_24hr: true
    }

    super.connect();
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return "";
    }
  }
}