import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['withdrawalDescription', 'withdrawalPeriod'];

  connect() {
    this.toggleDaysPicker()
  }

  toggleDaysPicker() {
    let value = this.withdrawalDescriptionTarget.value;
    if (value === 'one' || value === 'two') {
      this.withdrawalPeriodTarget.classList.add('d-none');
    } else {
      this.withdrawalPeriodTarget.classList.remove('d-none');
    }
  }
}