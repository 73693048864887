import AsyncFormController from './async_form_controller'

export default class extends AsyncFormController {
  static targets = ['links', 'template'];
  
  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields'
  }

  removeProduct(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    let { currentTarget } = ev;
    $(currentTarget).closest('.nested-item').remove();
  }

  add_association(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
    this.reinitSelect2();
    event.currentTarget.blur();
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest('.' + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector('input[name*="_destroy"]').value = 1
      wrapper.style.display = 'none'
    }
  }
}